import React from 'react'
import Layout from '../components/Layout'
import Pagination from '@mui/material/Pagination';
import {
    SearchClient,
    AzureKeyCredential,
    SearchResult
} from "@azure/search-documents";
import { WindowLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Breadcrumbs, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import SearchBar from '../components/SearchBar';
import {useSearch as useSearchContext} from '../context/SearchContext';
import ReactHtmlParser from 'html-react-parser';
import { appInsightClient } from '../utils/AppInsightApiCall';
import CircularProgress from '@mui/material/CircularProgress';
import {Link} from 'gatsby';
import { HttpOperationResponse } from '@azure/storage-blob';


// PageIndexType interface

export interface PageIndexType {
    id: string;
    title: string;
    excerpt: string;
    slug: string;
    lastEdit: Date;
}

export default function Search(props: {
    location: WindowLocation<{ 
        searchQuery: string 
    }>;
}) {
    // set the number of results per page 
    const resultsPerPage: number = 10;
    // create the search interface the will use the api the get search queries
    const searchInterface = useSearch<PageIndexType>({
        clientUrl: `https://${process.env.GATSBY_AZURE_SEARCH_NAME}.search.windows.net/`,
        indexName: process.env.GATSBY_AZURE_SEARCH_INDEX,
        apiKey: process.env.GATSBY_AZURE_SEARCH_KEY,
        initialQuery: props.location.state && props.location.state.searchQuery,
    });



    const [results, setQuery, page, setPage, count, isLoading] = searchInterface

    const {searchQuery} = useSearchContext()

    // create the breadcrumbs for the search
    const renderBreadCrumbs = (document: Pick<PageIndexType, keyof PageIndexType>) => {
        const parent = document.slug.split('/')[2];
        const child = document.slug.split('/')[3];

        return (
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color={theme => theme.palette.primary.main} sx={{textDecoration: 'none'}} variant="subtitle1" component={Link} to="/">
                        Home
                    </Typography>
                    <Typography color={theme => theme.palette.primary.main} sx={{textDecoration: 'none'}} variant="subtitle1" component={Link} to={`/${document.slug.split("/")[1]}`}>
                        Getting Started
                    </Typography>
                    {parent !== "" ? (
                    <Typography color={theme => child !== "" ? theme.palette.primary.main : ""} sx={{textDecoration: 'none'}} variant="subtitle1" component={child !== "" ? Link : 'div'} to={`/${document.slug.split("/")[1]}/${parent}`}>
                        {parent}
                    </Typography>
                    ) : ("")}
                    {child !== "" ? (
                        <Typography variant='subtitle1'>
                            {document.title}
                        </Typography>
                    ) : ""}
                </Breadcrumbs>   
            </div>
        )
    }


    


    React.useEffect(() => {
        if (props.location.state && props.location.state.searchQuery !== null && searchQuery === null) {
            setQuery(props.location.state.searchQuery)
        } else {
            setQuery(searchQuery)
        }
    }, [searchQuery])


    return (
        <Layout location={props.location}>
            <Helmet title="SEM Wiki - Search Result" defer={false} /> {/* document title of the page */}
            <Box sx={{ paddingTop: '40px', paddingBottom: '50px', marginLeft: '5.56%', marginRight: '5.56%', minHeight: '1000px'}}>
                <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '40px', color: '#666666' }}>
                    <Button size='small' style={{
                        fontWeight: '400px', fontSize: '14px', lineHeight: '16px',
                        textTransform: 'capitalize', margin: '0px', minWidth: '0px'
                    }} href='/'>
                        Home
                    </Button>
                    <Typography style={{ fontWeight: '400px', fontSize: '14px', lineHeight: '16px' }}>
                        Search
                    </Typography>
                </Breadcrumbs>
                <Box sx={{ marginLeft: '6.25%', marginRight: '6.25%', display: 'flex', flexDirection: 'column',
                           alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px'}}>
                    {/* Search Results title in the page*/}
                    <Typography style={{
                        fontSize: '32px', marginBottom: '12x',
                        fontWeight: '700', color: '#000000'
                    }}>
                      Search Results
                    </Typography>
                    <Grid container sx={{margin: 2, justifyContent: 'center'}}>
                        <SearchBar
                        placeholder='Search topics'
                        border='1px solid #999999'
                        minWidth="615px"
                        />
                    </Grid>
                </Box>
                {/* Create a container that will hold all the results */}
                <Box sx={{ display:'flex', flexDirection:'column', marginLeft: '6.25%', marginRight: '6.25%' }}>
                    {isLoading && (
                    <Grid container justifyContent="center">
                        <Grid item>
                            <CircularProgress color="success"/>
                        </Grid>
                    </Grid>
                    )}

                    {count !== 0 && (
                    results.map(({document, highlights}) => {

                        const {slug, id, title, excerpt} = document
                        
                        return (
                        <Box key={id} sx={{ marginTop: '30px', marginBottom: '30px'}}>
                            <Typography component="a" href={slug} sx={{fontWeight: 700, color: (theme) => theme.palette.darkColors.black80, fontSize: "20px", textDecoration: 'none'}}>
                                {highlights !== undefined ? highlights.title.map(title => (<div key={id}>{ReactHtmlParser(title)}</div>)) : title}
                            </Typography>
                            {renderBreadCrumbs(document)}
                            <Typography paragraph sx={{marginBottom :'0px', color: '#666666', fontSize: '16px', fontWeight: '400'}}>
                                {excerpt}
                            </Typography>
                            <Box sx={{height:'30px'}}/>
                            <Divider/>
                        </Box>
                        )
                    }) 
                    )}

                    { !isLoading && count === 0 && (
                        (
                            <Box>
                                <Typography variant="h6">
                                    Sorry we didn&apos;t find any documents
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Please Refine your search query
                                </Typography>
                            </Box>
                        )
                    )} 


                    {/* Pagination */}
                    <Box sx={{paddingTop: '30px'}}>
                        <Pagination
                            count={Math.ceil(count / resultsPerPage)}
                            color='primary'
                            page={page}
                            onChange={setPage}
                            disabled={isLoading}
                        />
                    </Box>
                </Box>
            </Box>
        </Layout>
    )
}

// UseSearchOptions type gets the required url, index name, queries, and the number of pages

export interface UseSearchOptions {
    clientUrl?: string;
    indexName?: string;
    apiKey?: string;
    initialQuery?: string;
    resultsPerPage?: number;
}

export function useSearch<T>({
    clientUrl,
    indexName,
    apiKey,
    initialQuery = "",
    resultsPerPage = 10,

}: UseSearchOptions) {
    const [query, setQuery] = React.useState<string>(initialQuery);
    const [page, setPage] = React.useState(1)
    const [count, setCount] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(true)
    const searchArray: SearchResult<Pick<T, keyof T>>[] = []
    const [results, setResults] = React.useState<
        SearchResult<Pick<T, keyof T>>[]>(searchArray);


    // create the client instance for the azure search api
    const client = React.useMemo(() => {
        return new SearchClient<T>(
            clientUrl,
            indexName,
            new AzureKeyCredential(apiKey)
        );
    }, []);

    // result will change at runtime depending on the user's query 
    React.useEffect(() => {
        setPageAndSearch(null, 1);
    }, [query]);


    // get the result and show it in after loading all the data in azure search cognitive
    const setPageAndSearch = (event: any, value: number) => {
        setPage(value);
        client
            .search(query, {
                top: resultsPerPage,
                skip: (value - 1) * resultsPerPage,
                includeTotalCount: true,
                highlightFields: "title",
                highlightPreTag: "<mark>",
                highlightPostTag: "</mark>",
                requestOptions: {
                    customHeaders: {
                        'Access-Control-Expose-Headers': 'x-ms-azs-searchid',
                        'x-ms-azs-return-searchid': 'true'
                    },

                    shouldDeserialize: (response) => {
                        const searchId = response.headers.get('x-ms-azs-searchid')

                        console.log(response);
                        const { parsedBody }  = response

                        const getParsedBody: {value: PageIndexType[]} = parsedBody

                        const searchCount: number = getParsedBody.value.length

                        if (searchCount > 0) {
                            appInsightClient.trackEvent({name: "Search"}, {
                                Query: query,
                                SearchId: searchId,
                                SearchServiceName: "semwiki-search",
                            })
                        }
                        return true
                    }
                }
            })
            .then(async (res) => {
                let arr = [];
                setCount(res.count);
                for await (let result of res.results) arr.push(result);
                setResults(arr);
            })
            .finally(() => setIsLoading(false));
    };
    return [results, setQuery, page, setPageAndSearch, count, isLoading] as [
        SearchResult<Pick<T, keyof T>>[],
        React.Dispatch<React.SetStateAction<string>>,
        number,
        (event: any, value: number) => void,
        number,
        boolean
    ];
}


